import React from "react";
import PropTypes from "prop-types";

const VisualARQImage = ({ rootCssClasses }) => (
  <svg
    viewBox="0 0 561 272"
    width="561"
    height="272"
    className={rootCssClasses}
  >
    <g transform="matrix(1,0,0,1,-65.7616,-170.038)">
        <g>
            <path d="M188.77,224.706L188.77,201.561C188.77,201.561 184.933,187.405 172.169,183.255C168.345,182.015 162.669,181.155 154.867,181.629C139.709,182.553 121.702,182.738 117.067,180.054C114.469,178.554 113.263,176.143 112.514,174.411C112.131,173.525 112.145,174.241 112.145,174.241L114.566,197.057C114.566,197.057 117.17,204.058 126.565,205.358C135.969,206.657 161.365,205.43 164.667,205.358C180.143,205.017 186.678,220.381 188.349,224.969C188.59,225.623 188.77,225.185 188.77,224.706Z" fill="rgb(21,118,153)" fillRule="nonzero" stroke="rgb(21,118,153)" strokeWidth="1.03px"/>
            <path d="M173.948,237.662L173.948,214.01C173.948,214.01 170.113,199.855 157.349,195.704C153.524,194.464 147.846,193.604 140.045,194.078C124.89,195.002 106.883,195.186 102.246,192.504C99.65,191.007 98.444,188.592 97.695,186.858C97.312,185.972 97.323,186.688 97.323,186.688L99.744,209.506C99.744,209.506 102.349,216.511 111.745,217.807C121.148,219.107 146.542,217.879 149.849,217.807C165.322,217.466 171.858,232.828 173.529,237.416C173.768,238.076 173.948,237.662 173.948,237.662Z" fill="rgb(27,148,192)" fillOpacity="0.8" fillRule="nonzero"/>
            <path d="M173.948,237.357L173.948,214.009C173.948,214.009 170.113,199.856 157.349,195.704C153.524,194.464 147.846,193.605 140.045,194.078C124.89,195.001 106.883,195.186 102.246,192.505C99.65,191.008 98.444,188.593 97.695,186.858C97.312,185.973 97.323,186.688 97.323,186.688L99.744,209.505C99.744,209.505 102.349,216.51 111.745,217.806C121.148,219.108 146.542,217.88 149.849,217.806C165.322,217.466 171.858,232.829 173.529,237.417C173.768,238.076 173.948,237.71 173.948,237.357Z" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.89px"/>
            <path d="M98.14,194.271C98.528,195.166 100.363,200.882 113.472,202.539C120.359,203.408 138.114,201.58 146.07,201.58C154.026,201.58 160.963,203.808 166.25,208.328C171.534,212.85 173.947,220.147 173.947,220.147" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.89px"/>
            <path d="M102.794,213.728L100.15,190.835" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.89px"/>
            <path d="M107.93,216.868L104.965,193.801" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.89px"/>
            <path d="M114.56,218.092L111.802,194.392" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.89px"/>
            <path d="M122.015,218.377L119.639,194.684" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.89px"/>
            <path d="M129.596,218.377L127.859,194.612" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.89px"/>
            <path d="M137.623,218.213L136.179,194.199" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.89px"/>
            <path d="M145.389,217.892L143.812,194.034" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.89px"/>
            <path d="M152.864,217.944L151.882,194.396" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.89px"/>
            <path d="M159.567,219.943L158.82,196.348" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.89px"/>
            <path d="M164.594,223.427L164.23,199.534" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.89px"/>
            <path d="M168.2,227.422L168.07,203.215" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.89px"/>
            <path d="M170.857,231.552L171.044,207.426" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.89px"/>
            <path d="M155.657,247.991L155.657,224.334C155.657,224.334 151.817,210.181 139.055,206.033C135.233,204.789 129.557,203.93 121.755,204.407C106.596,205.329 89.131,205.186 84.496,202.51C81.897,201.005 80.151,198.918 79.401,197.186C79.019,196.302 79.035,197.015 79.035,197.015L81.455,219.83C81.455,219.83 84.059,226.831 93.456,228.135C102.859,229.433 128.253,228.205 131.555,228.135C147.031,227.795 153.562,243.154 155.237,247.742C155.475,248.397 155.657,247.991 155.657,247.991Z" fill="rgb(77,187,225)" fillOpacity="0.8" fillRule="nonzero"/>
            <path d="M155.657,247.571L155.657,224.334C155.657,224.334 151.817,210.181 139.055,206.033C135.233,204.789 129.557,203.93 121.755,204.407C106.596,205.329 89.719,205.543 85.084,202.87C82.485,201.365 80.151,198.918 79.401,197.185C79.019,196.302 79.035,197.015 79.035,197.015L81.455,219.83C81.455,219.83 84.059,226.831 93.456,228.135C102.859,229.433 128.253,228.205 131.555,228.135C147.031,227.794 153.562,243.154 155.237,247.742C155.475,248.397 155.657,248.019 155.657,247.571Z" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.45px"/>
            <path d="M80.657,212.286C80.768,213.327 82.804,219.023 92.727,221.079C99.567,222.493 116.567,221.171 125.034,220.983C133.502,220.79 140.305,221.187 146.477,225.833C152.653,230.48 155.071,239.52 155.071,239.52L155.581,240.984" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.45px"/>
            <path d="M79.795,204.194C79.92,205.366 84.153,212.408 94.721,213.022C99.685,213.31 121.011,212.577 130.118,212.577C139.218,212.577 143.357,215.374 147.241,218.369C151.123,221.36 155.07,230.272 155.07,230.272L155.646,231.484" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.45px"/>
            <path d="M84.504,224.062L81.837,200.396" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.45px"/>
            <path d="M89.368,227.092L87.162,203.684" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.45px"/>
            <path d="M96.035,228.419L93.766,204.723" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.45px"/>
            <path d="M103.679,228.702L101.332,205.012" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.45px"/>
            <path d="M111.321,228.702L109.626,204.937" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.45px"/>
            <path d="M119.327,228.541L117.872,204.723" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.45px"/>
            <path d="M127.094,228.213L125.513,204.283" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.45px"/>
            <path d="M134.544,228.267L133.589,204.724" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.45px"/>
            <path d="M141.271,230.272L140.53,206.676" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.45px"/>
            <path d="M146.301,233.742L145.939,209.858" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.45px"/>
            <path d="M149.936,237.746L149.779,213.54" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.45px"/>
            <path d="M152.944,242.576L153.13,218.131" fill="none" fillRule="nonzero" stroke="rgb(27,148,192)" strokeWidth="0.45px"/>
        </g>
    </g>
  </svg>
);

VisualARQImage.propTypes = {
  rootCssClasses: PropTypes.string,
};

VisualARQImage.defaultProps = {
  rootCssClasses: "",
};

export default VisualARQImage;
